
import React, { useState, useContext, useEffect } from "react";
import Image from "react-bootstrap/Image";


const MainImage = (props)=>{


    const imageURL =  "../../images/landingpage.jpg";
    const text = "";

    return (
    <div style={{marginBottom:'10px'}}>
            <Image
                src={require('../../../images/landingpage2.png')}
                width="100%"
                height="auto"
                alt="Photo by Efdal YILDIZ from Pexels"
    
            />
        </div>
   
    )
}

export default MainImage;