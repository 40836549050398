import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../store";
import Image from "react-bootstrap/Image";
import MainImage from '../components/atoms/imagetext/MainImage';
import CollectionCards from "./atoms/CollectionCards";

let Home = (props) => {
  const {
    
    CollectionNames
  } = useContext(StoreContext);


  return (
    <div class="center" style={{ display:'flex',flexDirection:'column',  width:'100%', height:'100%', textAlign:'center'}} >
      <div>
      <MainImage></MainImage>  
      </div>  
      <div style={{marginTop:'20px'}}>
        <CollectionCards Title="Galleries" CollectionNames={CollectionNames}/>
      </div>
    </div>
  );
};

export default Home;
