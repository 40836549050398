import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../store";
import Image from "react-bootstrap/Image";
import CollectionWrapper from './atoms/imagetext/MainImage';
import Products from "./products";
import NoPhoto from "./atoms/NoPhoto";
import CollectionCards from "./atoms/CollectionCards";
import { useParams } from "react-router-dom";
import DropDown from "./atoms/dropdown/DropDown";
import DropDownCheckBox from "./atoms/dropdown/DropDownCheckBox";
import PageHeader from "../components/atoms/texts/PageHeader";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import ContactUsModal from "../components/atoms/molecules/ContactUsModal";


const CollectionPage = (props)=>{

  const { collectionname} = useParams();

  const filterOptions = ['All','Cocks','Hens','For Sale','Imports'];
    const {
        SortOptions, 
        Collections
      } = useContext(StoreContext);
    
    const imageURL =  "../../images/landingpage.jpg";
    const text = "";

    const [showContactModal, setShowContactModal] = useState(false);
      
    const [sortBy, setSortBy] = useState(SortOptions[0])
    const [collections, setCollections] = useState(Collections?.filter((item)=>item.collectionname==collectionname));

    

    const sortIt = (list)=>{


      switch (sortBy) {
        case 'A-Z':
          return list.sort((a,b)=>(a.title.localeCompare(b.title)))
          break;
        case 'Z-A':      
         return list.sort((a,b)=>(b.title.localeCompare(a.title)));
          break;
        default:
          console.log(`Sorry,unknown ${sortBy}.`);
      }
    }


//     useEffect(()=>{



// switch (sortBy) {
//   case 'A-Z':
//     console.log('sorting a-z');
//     //a.firstname.localeCompare(b.firstname)
//     console.log(collections.sort((a,b)=>(a.title.localeCompare(b.title))));
//     var y = collections.sort((a,b)=>(a.title.localeCompare(b.title)))
//     setCollections(y);
//     break;
//   case 'Z-A':
//     console.log('sorting z-a');
//     //console.log(collections.sort((a,b)=>(b.title - a.title)));
//     console.log(collections.sort((a,b)=>(b.title.localeCompare(a.title))));
//     var x = collections.sort((a,b)=>(b.title.localeCompare(a.title)));
//     setCollections(x);
//     break;
//   default:
//     console.log(`Sorry,unknown ${sortBy}.`);
// }

//     },[sortBy]);

    //const _collections = Collections.filter((item)=>item.collectionname==collectionname);

    // const data = [{'Title':'Bird1','Description':'Description asdfasdf', 'Image':'2xwinner-removebg-preview.png', 'CollectionName':'Winners'},
    //                     {'Title':'Bird2','Description':'Description 1234', 'Image':'381-removebg-preview.png', 'CollectionName':'Breeders'}]


    const onSelectSort = (item)=>{
      setSortBy(item);
    }
    
  const displayCollection = () =>{
    return(
              
    <div style={{ display:'flex',flexDirection:'row', justifyContent:'center', flexWrap:'wrap'}}>

       {sortIt(collections)?.map((item)=>{
         const {images,title} = item;

         return(
           <NavLink
           as={Link}
           to={`../productDetail/${item.id}`}
           activeClassName="active"
           style={{ textDecoration: "none" }}
         >
           <div style={{ margin:'10px', backgroundColor:'#F5F5F5', border:'0px solid gray', padding:'10px'}}>
             <div>
                <Image   src={images[0]}  style={{ width: "auto", height: "250px" }}/>   
              </div>
              <div>
                <span style={{top:'50%'}} style={{'fontWeight':'bold'}}>{title}</span>
              </div>
           </div>
           </NavLink>)
  })}
     

     </div>
    )
    }

    return (
    <div style={{marginBottom:'10px', textAlign:'center'}}>
      <PageHeader Text={collectionname}></PageHeader>
      <div style={{ border:'1px solid #DCDCDC', height:'auto', display:'flex'}}>
      <div style={{padding: '10px'}}>
          <DropDown Label="Sort By" Options={SortOptions} Action={onSelectSort}></DropDown>
       </div>
       {/* <div style={{padding: '10px'}}>
        <DropDownCheckBox Label="Filter By" Options={filterOptions}></DropDownCheckBox>
       </div> */}
      </div>

    {collections.length > 0 ? displayCollection():<div style={{margin:'20px'}}><h3>This Collection is empty, check back in a later time or <span style={{fontStyle:'italic', color:'#019efc'}} onClick={() => setShowContactModal(!showContactModal)}>click here to drop us a message</span>. </h3></div>}
        

    {showContactModal ? (
          <ContactUsModal
            show={showContactModal}
            closeModal={() => setShowContactModal(!showContactModal)}
          />
        ) : null}
        </div>
   
    )
}

export default CollectionPage;