import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../store";
import Image from "react-bootstrap/Image";
import CollectionWrapper from './atoms/imagetext/MainImage';
import Products from "./products";
import NoPhoto from "./atoms/NoPhoto";
import CollectionCards from "./atoms/CollectionCards";
import PageHeader from "./atoms/texts/PageHeader";
import ImageLeftTextRight from "./atoms/imagetext/ImageLeftTextRight"


const AboutUsPage = (props)=>{

    const {
        ImageLocation, 
        Collections,
        CollectionNames
      } = useContext(StoreContext);
    
    const imageURL =  "../../images/landingpage.jpg";
    const text = "";




    return (
        <div style={{margin:'10px', textAlign:'center'}}>
            <PageHeader Text="About Us"></PageHeader>
            
            <div  style={{margin:'10px', display:'flex', justifyContent:'space-around',flexWrap:'wrap'}}>
        <div>
            <Image
                //src={require('../../components/images/landingpage2.png')}
                src="https://cdpowerhouse-uploads.s3.amazonaws.com/loft1.jpg"
                width="400px"
                height="auto"
                alt="Photo by Efdal YILDIZ from Pexels"
                

    
            />
            </div>
            <div style={{marginLeft:'10px',maxWidth:'500px'}}>
                <p style={{textAlign:'left',lineHeight:'2', letterSpacing:'.5px'}}><b>How it all started?</b> Chris has always been an animal lover, adventurer, and enjoys competition.  For those reasons he fell in love with this wonderful world of homing pigeons.  
                He started raising pigeons before he was a teen.  He enjoyed seeing them come home when they were taken away from the loft. He could still remember his first race against his neighbor (their own version of 1 bird derby). Waiting 
                on the roof to see whos bird will land first. His bird was ahead but it decided to make another lap around the loft before landing and lost the race, regardless he's been hooked since then. Unfortunately, he had to give up his birds when 
                they moved.  Fast forward to several years later, he found his way back to this wonderful hobby. He was fortunate to have met great flyers and mentors in the beginning of his come back. They were all very generous with their knowledge, birds, and everything he needed, 
                for that he'd always be greateful.   </p>
            </div>
        </div>
   
        <div  style={{marginBottom:'10px', display:'flex', justifyContent:'space-around', flexDirection:'row-reverse', flexWrap:'wrap'}}>
            <div style={{}}>
              <Image
                  //src={require('../../components/images/landingpage2.png')}
                  src="https://cdpowerhouse-uploads.s3.amazonaws.com/cdpowerhousecover.png"
                  width="400px"
                  height="auto"
                  alt="Photo by Efdal YILDIZ from Pexels"
      
              />
            </div>
            <div style={{marginLeft:'10px', maxWidth:'500px'}}>
                <p style={{textAlign:'left',lineHeight:'2', letterSpacing:'.5px'}}>
                <b>Our Birds</b> Powerhouse Loft started with birds from local flyers from the Tri-state such as Wing's Loft, Amoco Loft, Hillside Loft, and Glory Loft.  We're also fortunate to aquire a bird from Bunag Loft. Most of these bloodlines are still in our loft.  These bloodlines allowed us 
                to win a trophy, diplomas, and other prizes. Winning is great, but for now our real motivation is to develop a family of birds that have natural health and always ready to fly the distance. 
                </p>
            </div>
        </div>

   
        </div>
   
    )
}

export default AboutUsPage;