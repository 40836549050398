import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../store";
import Image from "react-bootstrap/Image";
import CollectionWrapper from '../components/atoms/imagetext/MainImage';
import Products from "./products";
import NoPhoto from "./atoms/NoPhoto";
import CollectionCards from "./atoms/CollectionCards";
import PageHeader from "./atoms/texts/PageHeader";



const CollectionsPage = (props)=>{

    const {
        ImageLocation, 
        Collections,
        CollectionNames
      } = useContext(StoreContext);
    
    const imageURL =  "../../images/landingpage.jpg";
    const text = "";



    
  const displayCollections = () =>{

    return(
              
    <div style={{ display:'flex',flexDirection:'row', justifyContent:'center'}}>

     
       {Collections?.map((item)=>(
           <div style={{ margin:'10px',border:'1px solid gray',width:'auto', height:'150px'}}>
             <Image   src={require('../images/' + item.Image)}  style={{ width: "100%", height: "100%", objectFit:'contain' }}
 /> 
           <span style={{top:'50%'}}>{item.Title}</span>
           
           </div>
     ))}
     

     </div>
    )
    }

    return (
        <div style={{marginBottom:'10px', textAlign:'center'}}>
            <PageHeader Text="Our Birds"></PageHeader>
            <CollectionCards  CollectionNames={CollectionNames}/>
        </div>
   
    )
}

export default CollectionsPage;