import React, { createContext, useReducer } from "react";
import ActionTypes from "./actionTypes";


//test image
const image1 = "https://gotcocos-accountid-uploads.s3.amazonaws.com/2xwinner-removebg-preview.png";
const url = "https://cdpowerhouse-uploads.s3.amazonaws.com/";
//test data
const collections=[
  {id:1,appid:1, datecreated:null, datemodied:null,collectionname:'cocks', title:'Diaval', description:'One of our best flyer in 2017. 3rd Champion Bird in the Club, and Auction Winner. Son of CJC Section Winner.',forSale:0,images:[url + "davial.png","https://cdpowerhouse-uploads.s3.amazonaws.com/452ped.JPG"], fields: {  sex:'cock',color:'dark check', size:'medium', strain:'Wings Loft' }}  
  ,{id:2,appid:1, datecreated:null, datemodied:null,collectionname:'cocks', title:'Grandson to Sungoddess of Oxford Loft', description:'Produced: Club winners and many consistent flyers',forSale:0,images:["https://cdpowerhouse-uploads.s3.amazonaws.com/sungoddessson-removebg-preview.png"], fields: {  sex:'cock',color:'blue check', size:'medium', strain:'Kannibal Houben' }}  
  ,{id:3,appid:1, datecreated:null, datemodied:null,collectionname:'cocks', title:'Kees Bosua of Amoco Loft', description:'Produced: Futurity Winner and many clocked birds in the 300 mile or more races.',forSale:0,images:["https://cdpowerhouse-uploads.s3.amazonaws.com/keesbosua-removebg-preview.png"], fields: {  sex:'cock',color:'blue check', size:'medium', strain:'Kees Bosua' }}  
  ,{id:6,appid:1, datecreated:null, datemodied:null,collectionname:'cocks', title:'Dijkstra x Leideman', description:'Beautiful Young cock, Dijkstra x Leideman.',forSale:0,images:[url + "quad53.png"], fields: {  sex:'cock',color:'blue check', size:'medium', strain:'Dijkstra x Leideman' }}  
  ,{id:7,appid:1, datecreated:null, datemodied:null,collectionname:'cocks', title:'Kannibal X Wolverine', description:'He flew the WTCM Race, his brother also flew the same race and was our first bird.',forSale:0,images:[url + "2103.png"], fields: {  sex:'cock',color:'blue bar', size:'large', strain:'Kannibal X Wolverine' }}  
  ,{id:8,appid:1, datecreated:null, datemodied:null,collectionname:'cocks', title:'Kannibal X Wolverine', description:'He flew the WTCM Race, and was our first bird.',forSale:0,images:[url + "2083.png"], fields: {  sex:'cock',color:'blue bar', size:'large', strain:'Kannibal X Wolverine' }}  

  ,{id:4,appid:1, datecreated:null, datemodied:null,collectionname:'hens', title:'Sheira', description:'She was 2x 1st in the club races, also earning a diploma from the combine.  ',forSale:0, images:[url + "2xwinner-removebg-preview.png"],fields: {sex:'hen',color:'red check', size:'medium', strain:'Glory Loft X Continental Class' }}
  ,{id:5,appid:1, datecreated:null, datemodied:null,collectionname:'hens', title:'Infinity', description:'She was a club winner and earning a diploma from the combine for placing 8th against 3,403 birds. ',forSale:0, images:[url + "381-removebg-preview.png"],fields: {sex:'hen',color:'blue bar', size:'medium', strain:'Foxhill Havenith X Wings Loft' }}
  ,{id:9,appid:1, datecreated:null, datemodied:null,collectionname:'hens', title:'2215', description:'Flew 6 races as yb, very consistent flyer. Sibling to multiple club winners.',forSale:0, images:[url + "2215.jpg"],fields: {sex:'hen',color:'blue check', size:'medium', strain:'Kannibal Houben X Verbruggen ' }}
  ,{id:10,appid:1, datecreated:null, datemodied:null,collectionname:'hens', title:'Warchant 2', description:'One of my best breeding hen. Produced multiple club winners. Of all my birds her babies had the highest chance of surviving the entire season, really amazing homing instinct. ',forSale:0, images:[url + "warchant2.jpg"],fields: {sex:'hen',color:'blue bar', size:'small', strain:'Verbruggen' }}
  ,{id:11,appid:1, datecreated:null, datemodied:null,collectionname:'for adoptions', title:'Birds Available For Adoptions (Pick up Only)', description:'These birds are available for adoption (Adoption fee is $30). All these birds were in my racing teams or were breeders. These birds are perfect to be used for breeding if you are just starting with the homing pigeon hobby.', forSale:0, images:[url + "foradoptions.jpg"],fields: {}}

];
//const collectionnames = [{name:'cocks', image:"https://cdpowerhouse-uploads.s3.amazonaws.com/breedercocks.jpg"},{name:'hens', image:"https://cdpowerhouse-uploads.s3.amazonaws.com/breederhens.jpg"},{name:'winners', image:image1},];
const collectionnames = [{name:'cocks', image:url + "davial.png"},{name:'hens', image:"https://cdpowerhouse-uploads.s3.amazonaws.com/2xwinner-removebg-preview.png"},{name:'for adoptions', image:"https://cdpowerhouse-uploads.s3.amazonaws.com/cock2-removebg-preview.png"},];
const initializedListing = {
  
  ListingID: 0,
  ListingData: {
    ListingID: 0,
    Title: "",
    Description: "",
    Cost: 0,
    Price: 0,
    Quantity: 0,
    Active: true,
    Images: [],
    CategoryID: 1,
    CategoryName: "",
    Condition: "New",
  },
  AccountID: "accountID",
  CreatedDate: null,
  ModifiedDate: null,
};

export const initialState = {
  LoftName:'Powerhouse Loft',
  Collections: collections,
  CollectionNames:collectionnames,
  Currency: "$",
  Categories: [],
  Products: [{ListingID:1,ListingData: {ForSale:0, Title:'Bird 1',Description:'blah blah blah blah', Images:['2xwinner-removebg-preview.png','2xwinner-removebg-preview.png','2xwinner-removebg-preview.png','2xwinner-removebg-preview.png'], Sex:'H'}}],
  Shoppingcart: [],
  NewProduct: initializedListing,
  IsLogin: false,
  SortOptions: ['A-Z', 'Z-A'],
  UserName: "",
  APIVersion: 0,
  AccountID: "accountID2",
  // BaseURL: "http://ec2-54-165-101-207.compute-1.amazonaws.com",
  //BaseURL: "http://localhost:50651/",
  BaseURL: "http://api.pigeonconnect.com/",
  ImageLocation: "https://cdpowerhouse-uploads.s3.amazonaws.com/",
};

function reducer(state, action) {
  switch (action.actionType) {
    case ActionTypes.LOAD_CATEGORIES:
      return {
        ...state,
        Categories: [...new Set(action.payload)],
      };
    case ActionTypes.LOAD_PRODUCTS:
      return {
        ...state,
        Products: [...new Set(action.payload)],
      };
    case ActionTypes.GET_API_VERSION:
      return {
        ...state,
        APIVersion: action.payload,
      };
    case ActionTypes.RESET_RACE:
      return {
        ...state,
        Race: action.payload,
      };
    //temp
    case ActionTypes.LOAD_SHOPPINGCART:
      return {
        ...state,
        Shoppingcart: state.Shoppingcart,
      };
    case ActionTypes.UPDATE_SHOPPINGCART_ITEM:
      return {
        ...state,
        Shoppingcart: action.payload,
      };
    case ActionTypes.ADD_TO_CART:
      return {
        ...state,
        Shoppingcart: [...new Set(action.payload)],
      };

    case ActionTypes.UPDATE_PRODUCT_QUANTITY:
      return {
        ...state,
        Products: action.payload,
      };
    case ActionTypes.UPDATE_SHOPPINGCART:
      return {
        ...state,
        Shoppingcart: action.payload,
      };

    case ActionTypes.UPDATE_AUTH_TOKEN:
      return {
        ...state,
        AuthToken: action.payload,
      };
    case ActionTypes.UPDATE_LOGIN:
      return {
        ...state,
        IsLogin: action.payload,
      };
    case ActionTypes.UPDATE_USERNAME:
      return {
        ...state,
        UserName: action.payload,
      };
  }
}

export const StoreContext = createContext({
  ...initialState,
  dispatch: undefined,
});

const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={{ ...state, dispatch: dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
