import React, { useContext, useEffect } from "react";
import { StoreContext } from "../../../store";
import {
  GetAPIVersion,
  UpdateLogin,
  Logout,
  SaveProductWithUpload,
  ActivityNotification
} from "../../../actions";
import { Link } from "react-router-dom";
import { faBold, faFlagUsa } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  const { APIVersion, dispatch, IsLogin } = useContext(StoreContext);

  // useEffect(() => {
  //   GetAPIVersion(dispatch);
  // }, []);

  useEffect(()=>{
    ActivityNotification(dispatch)
  },[]);

  // const handleLogout = () => {
  //   Logout(dispatch);
  // };

  const handleLogin = () => {
    //UpdateLogin(dispatch, true);
  };
  // useEffect(() => {
  //   console.log("is login" + IsLogin);
  // }, IsLogin);

  return (
    <div
      className="footer-container"
      style={{
        color: "#d8eaee",
        padding: "10px",
        backgroundColor: "slategrey",
        marginBottom: "0px !important",
        marginTop: "100px",
      }}
    >
      <div className="rectangle text-center">
        <p>
          <span style={{ fontFamily: "sans-serif" }}>
            Powerhouse Loft ©{new Date().getFullYear()}
          </span>
        </p>

        <p>
          <Link to="/admin">
            <FontAwesomeIcon icon={faFlagUsa} color="#083B54" size="lg" />
          </Link>
        </p>

        <p className="pigeon-connect">
          Developed and maintained by{" "}
          <a href="mailto:charmedcoder@gmail.com">CharmedCoder</a>
        </p>
      </div>
      <div className="counter-div"></div>
      <div className="api-version">
        {APIVersion == 0 ? "STATUS: API IS DOWN" : "API V" + APIVersion}- app
        V1.6
      </div>
      <br />
    </div>
  );
};
export default Footer;
