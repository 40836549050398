import React, { useState, useContext, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import { StoreContext } from "../store";
import "../App.css";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import ContactUsModal from "./atoms/molecules/ContactUsModal";
import { UpdateLogin, Logout } from "../actions";
import ShoppingCartIcon from "./atoms/molecules/ShoppingCartIcon";

let Navigation = (props) => {
  const { IsLogin, dispatch, LoftName } = useContext(StoreContext);

  const [showContactModal, setShowContactModal] = useState(false);
  const handleLogout = () => {
    Logout(dispatch);
  };

  const hideModalClick = () => {
    setShowContactModal(false);
  };

  const handleShowContact = () => {
    setShowContactModal(true);
  };

  return (
    <div>
      <div className="login-div">
        {IsLogin ? (
          <span onClick={() => handleLogout()}>
            Logout
            {/* <Link to="/admin">
              <mark className="text-danger">Logout</mark>
            </Link> */}
          </span>
        ) : (
          ""
        )}
      </div>

      <div className="nav-bar" style={{  'display':'flex', justifyContent:'start'}}>
        <div style={{'flexGrow':'2', fontSize:'30px', color:'#555555', fontWeight:'bold'}}>      
        {LoftName}
      </div>
        <div className="navSpan" style={{'flexGrow':'1'}}>
          <NavLink
            as={Link}
            to="/home"
            activeClassName="active"
            style={{ textDecoration: "none" }}
          >
            Home
          </NavLink>
        </div>
        <div className="navSpan" style= {{'flexGrow':'1', whiteSpace:'nowrap'}}>
          <NavLink
            as={Link}
            to="/collections"
            activeClassName="active"
            style={{ textDecoration: "none" }}
          >
            Our Birds
          </NavLink>
        </div>
        
        <div className="navSpan" style= {{'flexGrow':'1', whiteSpace:'nowrap'}}>
          <NavLink
            as={Link}
            to="/aboutus"
            activeClassName="active"
            style={{ textDecoration: "none" }}
          >
            About Us
          </NavLink>
        </div>

       
        <div style={{whiteSpace:'nowrap'}} className="navSpan" onClick={() => handleShowContact()}>
          <a>Contact Us</a>
        </div>
        {showContactModal ? (
          <ContactUsModal
            show={showContactModal}
            closeModal={() => hideModalClick()}
          />
        ) : null}
         {IsLogin ? (
          <div className="navSpan" style= {{'flexGrow':'1' ,whiteSpace:'nowrap'}}>
            <NavLink
              as={Link}
              to="/productsadmin"
              activeClassName="active"
              style={{ textDecoration: "none" }}
            >
              Manage Page
            </NavLink>
          </div>
        ) : null}
        {/* <div
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ShoppingCartIcon></ShoppingCartIcon>
        </div> */}
            <div style={{'flexGrow':'2', fontSize:'30px', color:'#555555'}}>      
      </div>
      </div>
    </div>
  );
};
export default Navigation;
