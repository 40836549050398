import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../../../store";

import Button from "react-bootstrap/Button";
//import "../../../../src/styles.less";
import ModalWrapper from "../modal/Modal";
import { SendMessage } from "../../../actions";

const ContactUsModal = (props) => {
  console.log("loaded contact us modal");
  const { dispatch } = useContext(StoreContext);

  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [Subject, setSubject] = useState("");
  const [error, setError] = useState(false);

  const [MessageBody, setMessageBody] = useState("");

  useEffect(()=>{
    if(props.Inquery !=null) setSubject(props.Inquery);
  },[]);

  let handleSend = () => {

    if(Name.length==0 || (Email.length==0 || Phone.length==0) ||MessageBody.length==0){
      setError(true);
      return;
      
    }
    setError(false);
    var Message = { Name: "", Email: "", Phone: "",Subject:"", MessageBody: "" };
    Message.Name = Name;
    Message.Email = Email;
    Message.Phone = Phone;
    Message.Subject = Subject;
    Message.MessageBody = MessageBody;
    Message.Source = "Powerhouse Loft Contact Page";

    SendMessage(dispatch, Message);
    props.closeModal();
  };
  return (
    <ModalWrapper
      title="Contact Form"
      show={props.show}
      closeModal={props.closeModal}
      handleOnClick={() => handleSend()}
    >
      <div className="contact-us-container">
        {/* <div className="row">
          <div className="col-sm text-left">
            <span className="font-weight-bold">Email Us at </span>

            <span>
              <a href="mailto:chris_dairo@yahoo.com"> chris_dairo@yahoo.com </a>
            </span>
          </div>
        </div> */}
        <div className="row">
          <div className="col-sm text-left">
            <span className="font-weight-bold">Fill up the form below</span>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-sm">
            <form>
              <ul className="flex-outer">
                <li>
                  <label for="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Your Name"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </li>
                <li>
                  <label for="name">Email</label>
                  <input
                    type="text"
                    id="email"
                    placeholder="Your Email"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </li>
                <li>
                  <label for="phone">Phone</label>
                  <input
                    type="text"
                    id="phone"
                    placeholder="Your Phone"
                    value={Phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </li>
                <li>
                  <label for="name">Subject</label>
                  <input
                    type="text"
                    id="subject"
                    placeholder="Subject"
                    value={Subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </li>
                <li>
                  <label for="Message">Message</label>
                  <textarea
                    className="contact-ta"
                    id="message"
                    value={MessageBody}
                    onChange={(e) => setMessageBody(e.target.value)}
                  />
                </li>
               {error? <li><span style={{color:'red'}}>Oops looks like you're missing some information.</span></li>:''}
              </ul>
            </form>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
export default ContactUsModal;
