import React, { useContext, useEffect, useState } from "react";
 import NoPhoto from "./NoPhoto";
// import Image from "react-bootstrap/Image";
 import { Link } from "react-router-dom";
 import Image from "react-bootstrap/Image";
 import { StoreContext } from "../../store";
import { useParams } from "react-router-dom";
// import { GetProducts, AddToCart } from "../../actions";
 import DropDown from "./dropdown/DropDown";
 import ImageGallery from "react-image-gallery";
 import "react-image-gallery/styles/css/image-gallery.css";
 import { faHeart } from "@fortawesome/free-solid-svg-icons";
 import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 import ReactTooltip from "react-tooltip";
import ActionButton from "./buttons/ActionButton";
import PageHeader from "./texts/PageHeader";
import ContactUsModal from "./../atoms/molecules/ContactUsModal"
const ProductDetail = (props) => {

  const {
Collections
  } = useContext(StoreContext);

  const [likes, setLikes] = useState(0);
  const [showContactModal, setShowContactModal] = useState(false);


  
  const handleShowContact = () => {
    setShowContactModal(true);
  };


  let { id } = useParams();
  const currency = "$";
  const listing = Collections?.find((item) => item.id == id);

  const { title,description,images, forSale,fields } = listing;

  const ImagesPath = images.map(function (item) {
    return {
      original:item, // ImageLocation + item,
      thumbnail: item,
    };
  });

  const renderListingDetailContent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "10px",
          justifyContent: "center",
          flexWrap:'wrap'
        }}
      >
        <div style={{ width: "auto", height: "auto" }}>
          <div
            style={{
              height: "auto",
              maxWidth: "100%",
            }}
          >
            <div style={imageDivStyle}>
              {images.length <= 0 ? (
                <NoPhoto></NoPhoto>
              ) : (
               
                  ImagesPath.length > 1 ?                 
                  <ImageGallery items={ImagesPath} showPlayButton={false} showThumbnails={false}/>:
             <ImageGallery items={ImagesPath} showPlayButton={false} showThumbnails={false}/>

               
              )}
Click the <Image src={require('../../images/fullscreen.JPG')} style={{display:'inline-block', width:'30px', height:'auto'}}/>     to view fullscreen
            </div>
          </div>
          {/* <div style={{ textAlign: "left" }}>
            <FontAwesomeIcon
              icon={faHeart}
              color="#6ea171"
              size="lg"
              onClick={() => setLikes(likes + 1)}
              data-tip={`${likes} heart this item`}
            />
            {likes}
          </div> */}
        </div>
        <div
          id="detail_right_box"
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            width: "500px",
            backgroundColor: "#F8F8F8",
            minHeight: "300px",
          }}
        >
          <div>
            <span
              style={{
                color: "#424756",
                fontSize: "20px",
                fontFamily: "fantasy",
              }}
            >
              {title}
            </span>
          </div>
          <div style={{ flexGrow: 3 }}>
            <p>{description}</p>
            <table style={{padding:'5px', border:'1px solid gray', width:'90%', textTransform:'capitalize'}}>
            
            {Object.entries(fields).map(([key,value]) =>               <tr>
                <td className="table-td" >{key}</td><td  className="table-td">{value}</td>
              </tr> )}


            </table>
          </div>         
        </div>
      </div>
    );
  };

  return (
    <div style={{ textAlign: "center" }}>
      <PageHeader Text={title}></PageHeader>
      {renderListingDetailContent()}
      <br></br>
      
        {(forSale>0) ?
        <div className="_row">
          <div>
          <Link to="/products" style={{ textDecoration: "none" }}>
            <ActionButton Label="Continue Shopping"></ActionButton>
          </Link>
        </div>
        <div>
          <Link to="/shoppingcart" style={{ textDecoration: "none" }}>
            <ActionButton Label="Shopping Cart"></ActionButton>
          </Link>
        </div>
        </div>
      :      
      <div className="_row">
      <div onClick={() => setShowContactModal(!showContactModal)}>
        <ActionButton Label="Contact Us About This Bird"></ActionButton>
      </div>
    </div>
    }
      <ReactTooltip effect="solid" />
      {showContactModal ? (
          <ContactUsModal
            show={showContactModal}
            closeModal={() => setShowContactModal(!showContactModal)}
            Inquery={title}
          />
        ) : null}
    </div>
  );

};

export default ProductDetail;

const descriptionStyle = {
  borderTop: ".5px solid gray",
  borderRadius: "10px",
  display: "flex",
  textAlign: "left",
  minWidth: "100px",
  lineHeight: "1.5",
  letterSpacing: "1px",
  fontSize: "16px",
  padding: "10px",
};
const productContainerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "#424756",
  padding: "20px",
  borderRadius: "10px",
  width: "100%",
  margin: "10px",
  color: "#e1d798",
};

const imageDivStyle = {
  justifyContent: "center",
  display: "flex",
  flexWrap: "wrap",
  width:'400px'
};

const inputStyle = {
  width: "300px",
  textAlign: "right",
  margin: "10px",
  // border: ".5px solid red",
};
