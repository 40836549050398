import axios from "axios";
import React, { useState, useContext, useEffect } from "react";

import { StoreContext, initialState, SelectedYear } from "./store";
import ActionTypes from "./actionTypes";
import { findDOMNode } from "react-dom";
import Products from "./components/products";
import Shoppingcart from "./components/shoppingcart";
const appID = 2;
const accountID = "3";
const AuthStr = "Bearer ".concat(accountID);


const config = { headers: { Authorization: AuthStr } };

const baseUrl = "http://api.pigeonconnect.com/api/";

//const baseUrl = "http://localhost:64567/api/";


export function GetAPIVersion(dispatch) {
  var endPoint = baseUrl + `shoppinglist/GetAPIVersion`;

  return axios
    .get(endPoint,config)
    .then(function (response) {
      dispatch({
        actionType: ActionTypes.GET_API_VERSION,
        payload: response.data,
      });
    })
    .catch(function (error) {
      console.log(error);
    });
}


export function SaveProductWithUpload(product, fileData, dispatch) {
  const formData = new FormData();
  for (let i = 0; i < fileData?.length; i++) {
    formData.append("file", fileData[i], fileData[i].name);
  }
  formData.append("metadata", JSON.stringify(product));
  const config = { headers: { Authorization: AuthStr } };

  var endPoint = baseUrl + `shoppinglist/PostItem`;

  return axios
    .post(endPoint, formData)
    .then(function (response) {
      dispatch({
        actionType: ActionTypes.LOAD_PRODUCTS,
        payload: [],
      });
      GetProducts(dispatch);
    })
    .catch(function (error) {
      ErrorHandler(error);
      dispatch({
        actionType: ActionTypes.UPDATE_LOGIN,
        payload: false,
      });
    });
}
export function DeleteImage(dispatch, accountInfoProduct) {
  accountInfoProduct.AccountInfo.AccountID = accountID;
  accountInfoProduct.AccountInfo.AppID = appID;

  var endPoint = baseUrl + `Catalog/DeleteImage`;
  return axios
    .post(endPoint, accountInfoProduct)
    .then(function (response) {
      GetProducts(dispatch);
    })
    .catch(function (error) {
      ErrorHandler(error);
    });
}

export function GetCategories(dispatch) {
  var endPoint = baseUrl + `shoppinglist/GetCategories`;

  return axios
    .get(endPoint)
    .then(function (response) {
      dispatch({
        actionType: ActionTypes.LOAD_CATEGORIES,
        payload: response.data,
      });
    })
    .catch(function (error) {
      console.log(error);
    });
}
export function GetProducts(dispatch) {
  var endPoint = baseUrl + `Shoppinglist/GetListingsByAccountID`;

    //const AuthStr = "Token ".concat(authToken.Token + "_" + accountID);
//   const config = { headers: { Authorization: AuthStr } }; 
  return axios
    .post(endPoint,null, config)
    .then(function (response) {
      dispatch({
        actionType: ActionTypes.LOAD_PRODUCTS,
        payload: response.data,
      });
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function GetShoppingcart(dispatch) {
  dispatch({
    actionType: ActionTypes.LOAD_SHOPPINGCART,
    payload: "",
  });

  // var endPoint = baseUrl + `shoppinglist/GetListingsByAccountID`;
  // var obj = { AppID: appID, AccountID: accountID, UserID: "" };

  // return axios
  //   .get(endPoint)
  //   .then(function (response) {
  //     dispatch({
  //       actionType: ActionTypes.LOAD_PRODUCTS,
  //       payload: response.data,
  //     });
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
}
export function UpdateShoppingcartItem(dispatch, item, shoppingcart) {
  // let newShoppingcart = shoppingcart.filter(
  //   (i) => i.Item.ListingID != item.Item.ListingID
  // );
  // newShoppingcart.push(item);

  let newShoppingcart;
  if (
    shoppingcart.find((i) => i.Item.ListingID == item.Item.ListingID) ==
    undefined
  ) {
    newShoppingcart = shoppingcart;
    newShoppingcart.push(item);
  } else {
    //loop thru shoppingcart and update the qty
    newShoppingcart = shoppingcart.map(function (i) {
      if (i.Item.ListingID == item.Item.ListingID) {
        i.Quantity = item.Quantity;
      }
      return i;
    });
  }
  //filter out if quantity is 0
  const filtered = newShoppingcart.filter((i) => i.Quantity > 0);
  dispatch({
    actionType: ActionTypes.UPDATE_SHOPPINGCART_ITEM,
    payload: filtered,
  });
}

export function UpdateQuantityInCart(
  dispatch,
  shoppingCart,
  products,
  productData
) {
  const scItem = shoppingCart.find(
    (item) => item.Item.ListingID == productData.ListingID
  );

  //update the quantity only
  shoppingCart.map(function (item) {
    if (item.Item.ListingID == productData.ListingID) {
      item.Quantity--;
      item.Total = item.Quantity * item.Item.Price;
    }
    return item;
  });

  dispatch({
    actionType: ActionTypes.ADD_TO_CART,
    payload: shoppingCart,
  });

  let updatedProducts = products.map(function (item) {
    if (item.ListingID == productData.ListingID) item.ListingData.Quantity++;
    return item;
  });
  dispatch({
    actionType: ActionTypes.UPDATE_PRODUCT_QUANTITY,
    payload: updatedProducts,
  });
}

export function AddToCart2(dispatch, shoppingCart, products, listingID) {
  const scItem = shoppingCart.find((item) => item.ListingID == listingID);
  const product = products.find((item) => item.ListingID == listingID)
    ?.ListingData;
  if (scItem == null) {
    const newItem = {
      ListingID: listingID,
      Item: product,
      Quantity: 1,
      Price: product.Price,
      Total: product.Price * 1,
    };

    shoppingCart.push(newItem);
  } else {
    scItem.Quantity++;
    scItem.Total = scItem.Quantity * scItem.Price;
  }
  //need to update quantity from listing
  //investigate this, i was not expecting this to work without updating via dispatch
  products.map(function (item) {
    if (item.ListingID == listingID) item.ListingData.Quantity--;
  });
  console.log(
    JSON.stringify(products.find((item) => item.ListingID == listingID))
  );
  // dispatch({
  //   actionType: ActionTypes.UPDATE_PRODUCT_QUANTITY,
  //   payload: shoppingCart,
  // });

  dispatch({
    actionType: ActionTypes.UPDATE_SHOPPINGCART,
    payload: shoppingCart,
  });
}

export function ClearShoppingcart(dispatch) {
  dispatch({
    actionType: ActionTypes.UPDATE_SHOPPINGCART_ITEM,
    payload: [],
  });
}

export function RemoveItemFromCart(dispatch) {}
//add item to cart
export function AddToCart(dispatch, shoppingCart, products, productData) {
  const scItem = shoppingCart.find(
    (item) => item.Item.ListingID == productData.ListingID
  );
  //first time adding to sc
  if (scItem == null) {
    const newItem = {
      Item: productData,
      Quantity: 1,
      Total: productData.Price * 1,
    };
    shoppingCart.push(newItem);
  } else {
    //update the quantity only
    shoppingCart.map(function (item) {
      if (item.Item.ListingID == productData.ListingID) {
        item.Quantity++;
        item.Total = item.Quantity * item.Item.Price;
      }
      return item;
    });
  }

  dispatch({
    actionType: ActionTypes.ADD_TO_CART,
    payload: shoppingCart,
  });

  let updatedProducts = products.map(function (item) {
    if (item.ListingID == productData.ListingID) item.ListingData.Quantity--;
    return item;
  });
  dispatch({
    actionType: ActionTypes.UPDATE_PRODUCT_QUANTITY,
    payload: updatedProducts,
  });
}

export function UpdateShoppingCart(
  dispatch,
  shoppingCart,
  products,
  listingID
) {
  const scItem = shoppingCart.find((item) => item.Item.ListingID == listingID);

  const product = products.find((item) => item.ListingID == listingID);

  product.ListingData.Quantity += scItem.Quantity;

  const filtered = shoppingCart.filter(
    (scItem) => scItem.Item.ListingID != listingID
  );
  // if (scItem.ListingID == listingID) {
  //   const product = products.find((item) => item.ListingID == listingID);
  //   product.ListingData.Quantity + scItem.Quantity;
  //   return null;
  // }
  // else {
  //   return scItem;
  // }

  shoppingCart = filtered;
  // dispatch({
  //   actionType: ActionTypes.UPDATE_PRODUCT_QUANTITY,
  //   payload: shoppingCart,
  // });

  dispatch({
    actionType: ActionTypes.UPDATE_SHOPPINGCART,
    payload: filtered,
  });

  console.log("shoppingcart" + JSON.stringify(shoppingCart));
  console.log("products" + JSON.stringify(products));
}
// export function DeleteRoster(dispatch, Roster, authToken) {
//   var endPoint = baseUrl + `ClubRoster/Remove`;
//   const AuthStr = "Token ".concat(authToken.Token + "_" + accountID);
//   const config = { headers: { Authorization: AuthStr } };

//   return axios
//     .post(endPoint, Roster, config)
//     .then(function (response) {
//       GetRosters(dispatch);
//     })
//     .catch(function (error) {
//       ErrorHandler(error);
//       dispatch({
//         actionType: ActionTypes.UPDATE_LOGIN,
//         payload: false,
//       });
//     });
// }
// export function Logout(dispatch) {
//   dispatch({
//     actionType: ActionTypes.UPDATE_AUTH_TOKEN,
//     payload: {},
//   });
//   dispatch({
//     actionType: ActionTypes.UPDATE_LOGIN,
//     payload: false,
//   });
// }


export function SendMessage(dispatch, message) {
  message.AccountID = accountID;
  message.Recipient = "christian.dairo@gmail.com";
  var endPoint = baseUrl + `Account/SendMessage`;

  return axios
    .post(endPoint, message, config)
    .then(function (response) {
      console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function ActivityNotification(dispatch) {


  var Message = { Name: "", Email: "", Phone: "",Subject:"", MessageBody: "" };
  Message.Subject = "Activity Notification";
  Message.MessageBody = "Activity Notification";
  Message.Source = "Powerhouse Loft Contact Page";
  Message.AccountID = accountID;
  Message.Recipient = "charmedcoder@gmail.com";

  var endPoint = baseUrl + `Account/SendMessage`;

  return axios
    .post(endPoint, Message, config)
    .then(function (response) {
      console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}


export function DeleteProduct(dispatch, product, authToken) {

}
export function Logout(dispatch) {
  dispatch({
    actionType: ActionTypes.UPDATE_AUTH_TOKEN,
    payload: {},
  });
  dispatch({
    actionType: ActionTypes.UPDATE_LOGIN,
    payload: false,
  });
}
export function Logging(dispatch, userName, password) {
  var endPoint = baseUrl + `Account/Authenticate`;

  //const AuthStr = "Bearer ".concat(accountID); //+ ", Token ".concat(initialState.Token);
  const config = {
    headers: { Authorization: AuthStr },
    params: { userName, password },
  };
  return axios
    .get(endPoint, config)
    .then(function (response) {
      dispatch({
        actionType: ActionTypes.UPDATE_AUTH_TOKEN,
        payload: response.data,
      });

      dispatch({
        actionType: ActionTypes.UPDATE_LOGIN,
        payload: response.data?.Token.length > 0 ? true : false,
      });
      dispatch({
        actionType: ActionTypes.UPDATE_USERNAME,
        payload: userName,
      });
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function UpdateLogin(dispatch, userName, password) {
  var endPoint = baseUrl + `Account/Login`;
  var x = " Token";
  const AuthStr = "Bearer ".concat(accountID, x); //+ ", Token ".concat(initialState.Token);
  const config = {
    headers: { Authorization: AuthStr },
    params: { userName, password },
  };
  return axios
    .get(endPoint, config)
    .then(function (response) {})
    .catch(function (error) {
      console.log(error);
    });
}

function ErrorHandler(error) {
  var msg = "";
  if (error.toString().includes("401")) {
    msg = "Invalid Authentication or token expired.  Please Login to continue.";
  }
  console.log(msg);
}
