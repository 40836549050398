import React, { useContext, useEffect, useState } from "react";

const ActionButton = (props) => {
  const btnLabel = props.Label;

  const handleOnClick = props.Action;

  return (
    <div
      style={{
        textAlign: "center",
        color: "white",
        backgroundColor: "#6ea171",
        padding: "15px",
        borderRadius: "10px",
        minWidth: "150px",
        margin: "5px",
      }}
      onClick={handleOnClick}
    >
      {btnLabel}
    </div>
  );
};

export default ActionButton;
