("use strict");
import React, { useState, useContext, useEffect } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navigation from "./components/navigation";
import Home from "./components/home";
import Products from "./components/products";
import Admin from "./components/admin";
import Image from "react-bootstrap/Image";
import StoreProvider from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/atoms/molecules/Footer";
import Filter from "./components/atoms/molecules/filter";
import ProductDetail from "./components/atoms/ProductDetail";
import ProductsAdmin from "./components/productsadmin";
import Shoppingcart from "./components/shoppingcart";
import CheckoutPage from "./components/checkout";
import CollectionsPage from "./components/CollectionsPage"
import CollectionPage from "./components/CollectionPage"
import AboutUsPage from "./components/AboutUsPage";

function App() {
  const headerStyle = {
    color: "red",
    textAlign: "center",
    margin: "20px",
    fontFamily: "Impact, Haettenschweiler,Arial Narrow Bold, sans-serif",
    letterSpacing: "5px",
  };

  const [filter, setFilter] = useState("All");
  const selectFilter = (event) => {
    setFilter(event.value);
  };

  return (
    <StoreProvider>
      <div className="main">
        <Router>
               <div>
            <div>
              <Navigation></Navigation>
            </div>     
          </div>

          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/home" exact component={Home} />
            <Route path="/collections" exact component={CollectionsPage} />
            <Route path="/aboutus" exact component={AboutUsPage} />
            
            {/* <Route path="/shoppingcart" exact component={Shoppingcart} />
            <Route path="/checkout" exact component={CheckoutPage} /> */}
            <Route path="/collections/:collectionname" exact component={CollectionPage} />
            <Route path="/productDetail/:id" exact component={ProductDetail} />
            <Route path="/productsadmin" component={ProductsAdmin} />
            <Route path="/admin" component={Admin} />
          </Switch>
          <Footer></Footer>
        </Router>
      </div>
    </StoreProvider>
  );
}

export default App;
